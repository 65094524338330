<template>
  <div class="list_body">
    <div :style="{ minHeight: '5vh' }">位置:一级页面/二级页面</div>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <!-- 搜索 -->
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="姓名:">
          <div style="background-color: red; width: 20px; higth: 10px"></div>
          <a-input placeholder="请输入" v-model="searchForm.searchText" :style="{ display: 'inline' }" :allowClear="true"/>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <a-button type="primary" @click="showModal"> 新增 </a-button>
      <!-- 新增/修改 -->
      <a-modal title="修改密码" :visible="visible" :confirm-loading="confirmLoading" @ok="addOk" @cancel="handleCancel" :mask-closable="false">
        <a-form-model layout="vertical" labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="用户名:">
            <a-input placeholder="请输入用户名" style="width: 220px" v-model="user.account" />
          </a-form-model-item>
          <a-form-model-item label="密码:">
            <a-input-password placeholder="请输入密码" style="width: 220px" v-model="user.pwd" />
          </a-form-model-item>
          <a-form-model-item label="确认密码:">
            <a-input-password placeholder="请再次输入密码"  style="width: 220px" v-model="user.enterPwd" />
          </a-form-model-item>
          <a-form-model-item label="状态:">
            <a-radio-group :default-value="true" v-model="user.status">
              <a-radio :value="true"> 启用 </a-radio>
              <a-radio :value="false"> 禁用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 表格 -->
      <a-table :columns="columns" :data-source="data" :pagination="datapager" @change="handleTableChange" :row-key="(record) => record.id">
        <span slot="num" slot-scope="text, record, index"> {{ (datapager.current - 1) * datapager.pageSize + index + 1 }}</span>
        <span slot="account" slot-scope="text">{{ text }}</span>
        <a-switch slot="status" slot-scope="text, record" @change="statusUpdate(record)" :checked="text" />
        <span slot="created" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="showUpdate(record)">编辑</span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="resetPwd(record)"> 重置密码 </span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="del(record)"> 删除 </span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'num' }, //customRender值不能叫title
  },
  {
    title: '用户名',
    dataIndex: 'account',
    key: 'account',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '创建时间',
    key: 'created',
    dataIndex: 'created',
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
// import Dashboard from '../Dashboard.vue';
const data = [];
export default {
  //   components: { Dashboard },
  data() {
    return {
      loading: false,
      addOk: this.handleOk,
      visible: false,
      confirmLoading: false,

      user: {},
      data, //表格数据，从服务端中获取
      columns, //表头
      searchForm: { searchText: '', solutionName: '', startTime: '', endTime: '' }, //查询参数
      datapager: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取列表 */
    async loadList() {
      //调用服务端接口获取文章列表
      let result = await this.axios({
        method: 'post',
        url: `user/search/${this.datapager.current}/${this.datapager.pageSize}`,
        data: this.searchForm,
      });
      var datum = result.data.data;
      for (let d of datum) {
        if (d.status == '正常') {
          d['status'] = true;
        } else {
          d['status'] = false;
        }
      }
      this.datapager.total = result.data.total;
      this.data = JSON.parse(JSON.stringify(datum));

    },
    /**确认查询 */
    searchSubmit(e) {
      this.loadList(); //重新读取列表
      e.preventDefault();
  
    },
    /**分页 */
    handleTableChange(pagination1, filters, sorter) {
      this.datapager = pagination1; //保存分页信息
      this.loadList(); //重新读取文章列表
    },
    /**修改状态 */
    async statusUpdate(value) {
      var updateValue = JSON.parse(JSON.stringify(value));
      if (value.status === true) {
        updateValue['status'] = '禁用';
      } else {
        updateValue['status'] = '正常';
      }
      let result = await this.axios({
        method: 'post',
        url: `user/update`,
        data: updateValue,
      });
      this.loadList();
    },
    /**新增 */
    showModal() {
      this.visible = true;
      this.addOk = this.handleOk;
    },
    /**新增确定 */
    async handleOk(e) {
      this.confirmLoading = true;
      if (this.user.status) {
        this.user.status = '正常';
      } else {
        this.user.status = '禁用';
      }
      try {
        let result = await this.axios({
          method: 'post',
          url: `user/save`,
          data: this.user,
        });
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.user = {};
      }, 500);
    },
    /**新增取消 */
    handleCancel(e) {
      this.user = {};
      this.visible = false;
    },
    /**修改 */
    showUpdate(obj) {
      this.visible = true;
      //深拷贝
      this.user = JSON.parse(JSON.stringify(obj));
      this.addOk = this.update;
    },
    /**修改 */
    async update() {
      this.confirmLoading = true;
      if (this.user.status) {
        this.user.status = '正常';
      } else {
        this.user.status = '禁用';
      }
      try {
        let result = await this.axios({
          method: 'post',
          url: `user/update`,
          data: this.user,
        });
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.user = {};
      }, 500);
    },
    /**重置密码 */
    async resetPwd(obj) {
      var resetUser = JSON.parse(JSON.stringify(obj));
      if (resetUser.status) {
        resetUser.status = '正常';
      } else {
        resetUser.status = '禁用';
      }
      resetUser.pwd="123456"
      try {
        let result = await this.axios({
          method: 'post',
          url: `user/update`,
          data: resetUser,
        });
        this.$message.success('重置密码成功');
      } catch (error) {
        this.$message.error('重置密码失败');
      }
    },
    /**删除 */
    del(obj) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let result = await that.axios.delete(`user/delete/${obj.id}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.a {
  background-color: aquamarine;
}
</style>
